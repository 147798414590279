import React, { useState, useContext, useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import MainFooter from '../../components/main-footer/main-footer';
import Dropdown from '../../components/dropdown/dropdown';
import { DataContext } from '../../data/DataProvider';
import './index.scss';
import DefaultLayout from '../../components/layouts/default-layout';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const block = 'page-exposicao-politica';
const dropdownItems = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    originalQuotation,
    setModal,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [isValid, setIsValid] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation?.sinistralidade)
      return navigate('/sinistralidade');
  }, [quotation, quotation?.sinistralidade]);

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };
  const save = () => {
    return changeValorParcela(
      quotation,
      responseMotor.response,
      setModal,
      setDisableSave,
      setOriginalQuotation
    );
  };

  const dropdownChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Exposição política',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        exposicaoPolitica: {
          existePessoaPoliticamenteExposta: selectedItem.value,
        },
      });
    },
    [quotation, setQuotation]
  );

  useEffect(() => {
    if (
      quotation &&
      quotation.exposicaoPolitica?.existePessoaPoliticamenteExposta === false
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [quotation]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/sinistralidade')}
          editMode={!!quotation?.propostaId}
          title="Pessoas politicamente expostas"
          description={
            <span>
              Confirme se nos últimos 5 anos os sócios ou algum de seus
              familiares (pais, filhos, enteados,
              <br />
              cônjuges e companheiros) desempenharam cargo político ou posição
              relevante em empresas
              <br />
              públicas do Governo Federal, conforme dispõe art.4º da Circular
              Susep 445/2012.
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
            {
              label: 'Sistemas protecionais',
              path: '/sistemas-protecionais',
            },
            {
              label: 'Sinistralidade',
              path: '/sinistralidade',
            },
            {
              label: 'Exposição política',
              path: '/exposicao-politica',
            },
          ]}
          fixed
          hide={quotation?.personalizado}
        />
        <div className={`container--large ${block}--fixed`}>
          <div className={`${block}--row`}>
            <div className={`${block}--item`}>
              <span className={`${block}__label`}>
                Informe se existem pessoas politicamente expostas:
              </span>
              <Dropdown
                className={`${block}__dropdown`}
                placeholder="Selecione"
                items={dropdownItems}
                changeHandler={dropdownChangeHandler}
                selectedValue={
                  quotation?.exposicaoPolitica?.existePessoaPoliticamenteExposta
                }
              />
            </div>
          </div>
        </div>

        <div className={`container--large`}>
          {isEditValid(quotation) && (
            <div className="action_buttons">
              <Button
                className="button_cta"
                type="button"
                theme={'secondary'}
                onClick={cancel}
                disabled={disableSave}
              >
                Cancelar edição
              </Button>
              <Button
                className={`button_cta button--margin-left`}
                theme={'tertiary'}
                onClick={save}
                disabled={!isValid || disableSave}
              >
                Salvar edição
              </Button>
            </div>
          )}
          {!quotation?.propostaId && (
            <Button
              className="button_cta"
              onClick={() => navigate('/coberturas')}
              disabled={!isValid}
            >
              Avançar
            </Button>
          )}
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
